// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  .container {
    @include make-container();
    @include make-container-max-widths();
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

@if $enable-grid-classes {
  .container-fluid {
    @include make-container();
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
  .row {
    @include make-row();
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters{
        margin-right : 0;
        margin-left  : 0;

        > .col,
        > [class*="col-"]{
            padding-right : 0;
            padding-left  : 0;
        }
    }
    // added pattern
    .gutter-6.row{
        margin-right : -6px;
        margin-left  : -6px;
    }
    .gutter-6 > [class^="col-"],
    .gutter-6 > [class^=" col-"]{
        padding-right : 6px;
        padding-left  : 6px;
    }

    .gutter-3.row{
        margin-right : -3px;
        margin-left  : -3px;
    }
    .gutter-3 > [class^="col-"],
    .gutter-3 > [class^=" col-"]{
        padding-right : 3px;
        padding-left  : 3px;
    }
    .gutter-1.row{
        margin-right : -1px;
        margin-left  : -1px;
    }
    .gutter-1 > [class^="col-"],
    .gutter-1 > [class^=" col-"]{
        padding-right : 1px;
        padding-left  : 1px;
    }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}
